import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

/** ACTIONS */
import { logoutAndRedirect } from '../../api/app/auth'

/**
 * Hook that provides a shortcut/centralized handler to logout the user
 */
const useHandleLogoutClick = () => {
	/** Use Redux Dispatch */
	const dispatch = useDispatch()
	/** Handle Logout of the user */
	const handleLogoutClick = useCallback(() => {
		console.log('LOGGED_OUT GTM')
		dispatch(logoutAndRedirect())
	}, [])

	return handleLogoutClick
}

export default useHandleLogoutClick
